<template lang="html">
  <div class="module-root">
    <slot v-if="ready" />
    <Portal to="leftSidebar">
      <slot v-if="ready" name="left" />
      <FrsLoadingIndicator :loading="!loaded" :error="error" />
    </Portal>

    <FrsLoadingIndicator :loading="!loaded" :error="error">
      <template #error>
        <IxButton back @click="backToDashboard">
          <IxRes>frs.buttons.moduleLoadFailed</IxRes>
        </IxButton>
      </template>
    </FrsLoadingIndicator>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

import IxButton from '@components/IxButton'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import ModuleRootMixin from '@mixins/ModuleRootMixin'

export default {
  components: {
    FrsLoadingIndicator,
    IxButton
  },
  mixins: [
    ModuleRootMixin
  ],
  methods: {
    ...mapMutations('fieldRecordSystem', [
      'setRightView'
    ]),
    backToDashboard () {
      this.setRightView('default')
    }
  }
}
</script>

<style lang="scss" scoped>
.module-root {
  margin: -5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 0;
}
</style>
