<template lang="html">
  <FrsModuleRoot
    :module="module"
    :module-path="['fieldRecordSystem', 'products']"
    :translation-namespaces="['SR_StateMessages', 'masterData', 'frs.products']"
    :setup="loadPrerequisiteData"
  >
    <router-view />

    <FrsLoadingIndicator
      :requests="['orgUnit.products.loadPrerequisiteData', 'orgUnit.products.saveProduct']"
    />
  </FrsModuleRoot>
</template>

<script>
import {mapActions} from 'vuex'

import FrsModuleRoot from '@frs/components/base/FrsModuleRoot'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    FrsLoadingIndicator,
    FrsModuleRoot
  },
  computed: {
    module () {
      return () => import('@store/modules/field-record-system/products')
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/products', [
      'loadPrerequisiteData'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
